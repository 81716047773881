<template>
    <div class="adjust-cell component flex-col flex-j-center flex-a-start tap box-sizing">
        <div class="flex-row flex-j-between flex-a-center" style="width: 100%;">
            <div class="flex-row flex-j-between flex-a-center">
                <span v-if="item.AdjustType==1" class="type type-1">需求</span>
                <span v-if="item.AdjustType==2" class="type type-2">供应</span>
                <span class="title">{{item.InfoName}}</span>
            </div>
            <span class="des">{{item.timeText}}</span>
        
        </div>
        <div style="height: .10rem"></div>
        <div class="flex-row flex-j-start flex-a-center" style="width: 100%;">
            <span class="des" style="margin-right: 0.2rem">工作地点：{{item.CompanyAdd}}</span>
            <span class="des">发布人：{{item.LinkMan}}</span>
        </div>
        <div class="btn-group">
            <span class="btn edit" @click="$emit('edit', item)">编辑</span>
            <span class="btn delete" @click="$emit('delete', item)">删除</span>
        </div>
    </div>
</template>

<script>
// adjust-cell
export default {
    name: "adjust-manage-cell",
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/adjust-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.adjust-cell {
    transition: all 0.3s ease;
    position: relative;
    & .btn-group {
        opacity: 0;
        position: absolute;
        right: 0;
        bottom: 0.1rem;
        z-index: 10;
        font-size: 0.32rem;
        & .btn {
            padding: 0.1rem 0.2rem;
            &:hover {
                cursor: pointer;
                font-size: 0.36rem;
            }
        }
        & .edit {
            color: #39a9ed;
        }
        & .delete {
            color: #f1676d;
        }
    }
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
        & .btn-group {
            opacity: 1;
        }
    }
}
.component {
    padding: .20rem;
    /* box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.2); */
    border-radius: .12rem;
    background-color: #fff;
    margin-bottom: .20rem;
    line-height: .60rem;
}

.component .image {
    width: 100%;
    min-height: 4.00rem;
    background-color: orange;
    border-radius: .12rem;
}

.component .title {
    font-size: .32rem;
    /* padding-bottom: 20rem; */
}

.component .des {
    font-size: .28rem;
    color: #999999;
}

.swipe-btn-group {
    width: 120px;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.swipe-btn-group .btn {
    width: 60px;
    height: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.swipe-btn-group .edit {
    background-color: rgb(88, 189, 106);
}

.swipe-btn-group .delete {
    background-color: rgb(218, 50, 49);
}

.price {
    color: #f1676d;
}

.type {
    padding: .04rem .10rem;
    border-radius: .06rem;
    color: #fff;
    font-size: .24rem;
    margin-right: .10rem;
    line-height: .40rem;
}

.type-1 {
    background-color: #f1676d;
}

.type-2 {
    background-color: #4eabe8;
}
</style>
