<template>
    <f-modal
        :title="title"
        :width="800"
        ref="FModal"
        @ok="handleOk"
        @cancel="handleCancel">
        <!--        自定义标签区域开始-->
        <a-form-model ref="ruleForm" :model="params" :rules="rules" v-bind="layout">
            <a-form-model-item has-feedback label="调剂名称" prop="InfoName">
                <a-input v-model="params.InfoName" type="text" autocomplete="off"/>
            </a-form-model-item>
    
            <a-form-model-item label="类型(需求/供应)" prop="AdjustType">
                <a-select v-model="params.AdjustType" placeholder="设置调剂类型">
                    <a-select-option value="1">需求方</a-select-option>
                    <a-select-option value="2">供应方</a-select-option>
                </a-select>
            </a-form-model-item>
    
            <a-form-model-item label="职位描述" prop="Details">
                <FEditor v-model="params.Details" :height="300"/>
            </a-form-model-item>
    
            <a-form-model-item has-feedback label="联系人" prop="LinkMan">
                <a-input v-model="params.LinkMan" type="text" autocomplete="off"/>
            </a-form-model-item>
    
            <a-form-model-item has-feedback label="联系方式" prop="LinkPhone">
                <a-input v-model="params.LinkPhone" type="number" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="详细工作地址" prop="CompanyAdd">
                <a-input v-model="params.CompanyAdd" type="text" autocomplete="off"/>
            </a-form-model-item>
            
           
            <a-form-model-item label="所在镇街" prop="AreaName">
                <a-select v-model="params.AreaName" @change="onSelect" placeholder="设置所在镇街">
                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        
        </a-form-model>
        <!--        自定义标签区域结束-->
    </f-modal>
</template>

<script>
// info-edit
import FModal from "@/components/FModal";

import FEditor from "@/components/FEditor";

export default {
    name: "adjust-edit",
    components: {FEditor, FModal},
    data() {
        return {
            //    自定义数据区域开始
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            params: {
                InfoName: "",
                AdjustType: "",
                Details: "",
                LinkMan: "",
                LinkPhone: "",
                AreaName: "",
                CompanyAdd: "",
            },
            rules: {
                InfoName: [{required: true, message: '请输入调剂名称', trigger: 'blur'},],
                AdjustType: [{required: true, message: '请设置调剂类型', trigger: 'change'}],
                Details: [{required: true, message: '请输入职位描述', trigger: 'change'},],
                LinkMan: [{required: true, message: '请输入联系人', trigger: 'blur'},],
                LinkPhone: [{required: true, message: '请输入联系方式', trigger: 'blur'},],
                AreaName: [{required: true, message: '请设置所在镇街', trigger: 'change'}],
                CompanyAdd: [{required: true, message: '请输入详细工作地址', trigger: 'blur'},],
            },
            educationList: [],
            areaNameList: [],
            postNameList: [],
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            //    自定义数据区域结束
            title: '编辑',
            onOk: () => {
            },
            onCancel: () => {
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    mounted() {
    },
    methods: {
        //    自定义方法区域开始
        async onSubmit() {
            await this.validate();
            
            const params = this.params;
            const url = params.Id || params.id ? '/BaseAdjust/UpdateFlexibleInfo' : '/BaseAdjust/CreateFlexibleInfo';
            const res = await $app.httpPost({
                path: url,
                params: params,
                loading: '正在提交',
            });
            if (res.ok) {
                $app.showToast({
                    title: params.Id || params.id ? '更新成功' : '创建成功',
                });
                this.onOk();
                this.$refs['FModal'].done();
            } else {
                this.$refs['FModal'].reset();
            }
            
        },
        async onShow() {
            this.areaNameList = $app.getStorageSync('areaList');
            if (!this.params.Id) {
                const params = this.params;
                // params.CompanyName = this.userInfo.CompanyName;
                params.CompanyAdd = this.userInfo.CompayAdd;
                params.AreaName = this.userInfo.AreaName;
                params.LinkMan = this.userInfo.MemberName;
                params.LinkPhone = this.userInfo.Phone;
                // params.relationId = this.userInfo.Id;
                this.params = params;
            }
        },
        // 强制设置下拉框数据
        onSelect(value) {
            console.log(value)
            const params = this.params;
            params.AreaName = value;
            this.params = params;
            this.$forceUpdate();
        },
        
        //    自定义方法区域结束
        
        validate() {
            return new Promise(resolve => {
                const __this = this;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        console.log('Form 校验错误');
                        __this.$refs['FModal'].reset();
                    }
                });
            })
        },
        handleOk() {
            this.confirmLoading = true;
            this.onSubmit();
        },
        handleCancel() {
            this.visible = false;
            this.onCancel();
        },
        show({params, onOk, onCancel}) {
            for (const key in params) {
                if (params[key]) {
                    this.params[key] = params[key]
                }
            }
            // this.params = params;
            this.title = params.id || params.Id ? '编辑' : '添加';
            this.$refs['FModal'].show();
            this.onOk = onOk;
            this.onCancel = onCancel;
            this.onShow();
        },
    }
}
</script>

<style scoped lang="less">
.info-edit {
}
</style>
